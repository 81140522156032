import { createContext, useContext, useReducer } from "react";

const AuthContext = createContext();

const initialState = {
  user: null,
  isLoggedIn: localStorage.getItem("USERDATA") ? true : false,
  // isLoggedIn: false,
  userData: null || JSON.parse(localStorage.getItem("USERDATA")),
  questionHistory: null,
  deleteQuestionId: null,
  pageData: localStorage.getItem("pageData") || "",
  mockTest: JSON.parse(localStorage.getItem("MockTest") || null),
  mockTestData: [],
  editTopicId: [],
  questionId: [],
  idToken: "",
  currentQuestion: {
    question: "",
    answer: "",
  },
  userSelectQuestion: null,
  PrevQuestionData: [
    {
      question: "",
      answer: "",
    },
  ],
};
// console.log("SSD",localStorage.getItem("USERDATA"));

const authReducer = (state, action) => {
  // console.log("state and action :::", state, action);
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case "USERDATA":
      return {
        ...state,
        userData: action.payload.userData,
      };
    case "IDTOKEN":
      return {
        ...state,
        idToken: action.payload.idToken,
      };
    case "QUESTIONHISTORY":
      return {
        ...state,
        questionHistory: action.payload.questionHistory,
      };
    case "PAGEDATA":
      return {
        ...state,
        pageData: action.payload.pageData,
      };
    case "MOCKTEST":
      return {
        ...state,
        mockTest: action.payload.mockTest,
      };
    case "MOCKTESTDATA":
      // console.log("Action Payload:", action.payload.mockTestData);
      return {
        ...state,
        mockTestData: action.payload.mockTestData,
      };

    case "DELETEQUESTIONID":
      return {
        ...state,
        deleteQuestionId: action.payload.deleteQuestionId,
      };
    case "EDITTOPIC":
      return {
        ...state,
        editTopicId: [...state.editTopicId, action.payload.editTopicId],
      };
    case "QUESTIONID":
      return {
        ...state,
        questionId: [...state.questionId, action.payload.questionId],
      };
    case "CURRENTQUESTION":
      return {
        ...state,
        currentQuestion: {
          question: action.payload.question,
          answer: action.payload.answer,
        },
      };
    case "USERSELECTQUESTION":
      return {
        ...state,
        userSelectQuestion: action.payload.userSelectQuestion,
      };
    case "PREVQUESTIONDATA":
      return {
        ...state,
        PrevQuestionData: [
          {
            question: action.payload.question,
            answer: action.payload.answer,
          },
        ],
      };
    case "RESET":
      return {
        ...state,
        questionId: [],
      };

    default:
      console.log("state", state);
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
export function useAuth() {
  return useContext(AuthContext);
}
